const GAConfig = {
    event: {
        click: "click_event",
        load: "load_event",
        error: "error_event",
        channel: "channel_path"
    },
    parameters: {
        action_on: "param_action_on",
        value: "param_value",
    },

    event_name:{
        click_check_again: "click_check_resume_again",
        click_edit_job_title: "click_edit_job_title",
        click_step_2_jobtitle_is: "click_step_2_jobtitle_is",
    }

}

export default GAConfig;