import { Modal } from "react-bootstrap"
import Lottie from "lottie-react";
import ErrorJson from "../assets/json/error-json.json";
import "./error-modal.scss";
import BUTTON_CLOSE from "../assets/images/close-button.png";
const ErrorModal = ({ showPopup, handlePopup, message, HandleTryagain }: any) => {


    const handleErrorClick = () => {
        handlePopup();
    }

    return (

        <>
            <div className="error-modal">
                <Modal
                    show={showPopup}
                    onHide={handlePopup}
                    backdrop="static"
                    keyboard={false}
                >

                    <Modal.Body>

                        <div className="d-flex flex-column mx-auto modal-error">
                            <img src={BUTTON_CLOSE} className="modal-close-error" onClick={handleErrorClick}></img>
                            <Lottie animationData={ErrorJson} loop={true} className="modal-json-error mx-auto my-auto" />
                            <span className="modal-error-text">
                                {message}
                            </span>
                            <button className="modal-button-try-again" onClick={HandleTryagain}>ลองอีกครั้ง</button>
                        </div>

                    </Modal.Body>

                </Modal>
            </div>
        </>
    )
}

export default ErrorModal;