const dev = {
    ga_4: "G-2FWT212069",
    gtm_id: "GTM-MDVT2FT",
    recaptcha_key: "6LfvrvAjAAAAACDZ-XoPlg4czx2sA40uIO2C_gxe",
    recaptcha_server_key: "6LfvrvAjAAAAANvdwwkHOef-9wOXodsSyODYXWjB",
    recaptcha_url: "https://www.google.com/recaptcha/api/siteverify",
    content_gen_url: "https://asia-southeast1-scoutout-mang-test-e17bf.cloudfunctions.net/contentgenerator/",
    upload_resume: {
        x_api_key: "p5U31xjsZ25hPSMx1mwFf5j11SYfB1c059OP8BQ8",
        base_url : "https://f18w8j76h5.execute-api.ap-southeast-1.amazonaws.com/dev"
    },
    api: {
        x_api_key: "p5U31xjsZ25hPSMx1mwFf5j11SYfB1c059OP8BQ8",
        jobnow_plus: "https://plus.jobnow.work/api/jobs/search?q=",
        base_url: "https://testlambda.scoutout.net:444/resume-checker"
    },
    firebase: {
        apiKey: "AIzaSyBZtAANzQO-IsrWT3Iwmj9i0_-5DjwONV8",
        authDomain: "scoutout-mang-prod.firebaseapp.com",
        databaseURL: "https://scoutout-mang-prod.firebaseio.com",
        projectId: "scoutout-mang-prod",
        storageBucket: "scoutout-mang-prod.appspot.com",
        messagingSenderId: "1045861674744",
        appId: "1:1045861674744:web:e3bf4ca8b3b6ecceab950d",
        measurementId: "G-ZB69LTZRJM"
    },
    app: {
        resume_checker: 'https://resume-checker-test.web.app',
        jobrec_click_url: 'https://plus.jobnow.work/job/',
        jobhack: 'https://jobhack.co?utm_source=RESUME_CHECKER',
        jobhack_article: 'https://jobhack.co/careers?utm_source=RESUME_CHECKER',
        oneprofile: 'https://oneprofile.me?utm_source=RESUME_CHECKER',
        personality: 'https://quiz.scoutout.co?utm_source=RESUME_CHECKER',
        about_so: 'https://scoutout.co?utm_source=RESUME_CHECKER',
        resume_builder: 'https://resume-builder.jobhack.co?utm_source=RESUME_CHECKER',
        terms: 'https://scoutout.co/terms-th/',
        privacy: 'https://scoutout.co/privacy-th/',
        salary_checker: 'https://salary.scoutout.co'
    },
    authen: {
        sso_url : "https://d3ia7xx4z5f1n0.cloudfront.net",
        client_id : "LEARN",
        redirect_uri_result : "http://localhost:4200/result",
        redirect_uri : "http://localhost:4200",
        grant_type : "authorization_code",
        length : 32,
        api: {
            host_url: "https://mysadev.scoutout.net",
            token: "/api/v2.0/oauth/token",
            profile: "/api/v2.0/manager/profile",
            user_profile: "/api/v2.0/user/profile",
            refresh_token: "/api/v2.0/oauth/token"
        },
        rama: {
            host_url: "https://ramadev.scoutout.net",
            create_users: "/api/users",
            profile: "/api/me/profile",
            uploadFile: "/api/me/profile/files"
        }
    },
};
const test = {
    ga_4: "G-2FWT212069",
    gtm_id: "GTM-MDVT2FT",
    recaptcha_key: "6LfvrvAjAAAAACDZ-XoPlg4czx2sA40uIO2C_gxe",
    recaptcha_server_key: "6LfvrvAjAAAAANvdwwkHOef-9wOXodsSyODYXWjB",
    recaptcha_url: "https://www.google.com/recaptcha/api/siteverify",
    content_gen_url: "https://asia-southeast1-scoutout-mang-test-e17bf.cloudfunctions.net/contentgenerator/",
    upload_resume: {
        x_api_key: "p5U31xjsZ25hPSMx1mwFf5j11SYfB1c059OP8BQ8",
        base_url : "https://f18w8j76h5.execute-api.ap-southeast-1.amazonaws.com/dev"
    },
    api: {
        x_api_key: "p5U31xjsZ25hPSMx1mwFf5j11SYfB1c059OP8BQ8",
        jobnow_plus: "https://plus.jobnow.work/api/jobs/search?q=",
        base_url: "https://testlambda.scoutout.net:444/resume-checker"
    },
    firebase: {
        apiKey: "AIzaSyDO1IPRpvWzeP44a_x23R4j0gGH448kxp0",
        authDomain: "scoutout-mang-test-e17bf.firebaseapp.com",
        databaseURL: "https://scoutout-mang-test-e17bf.firebaseio.com",
        projectId: "scoutout-mang-test-e17bf",
        storageBucket: "scoutout-mang-test-e17bf.appspot.com",
        messagingSenderId: "436987830723",
        appId: "1:436987830723:web:e88150b88ca9a4dddc1d76"
    },
    app: {
        resume_checker: 'https://resume-checker-test.web.app',
        jobrec_click_url: 'https://plus.jobnow.work/job/',
        jobhack: 'https://jobhack.co?utm_source=RESUME_CHECKER',
        jobhack_article: 'https://jobhack.co/careers?utm_source=RESUME_CHECKER',
        oneprofile: 'https://oneprofile.me?utm_source=RESUME_CHECKER',
        personality: 'https://quiz.scoutout.co?utm_source=RESUME_CHECKER',
        about_so: 'https://scoutout.co?utm_source=RESUME_CHECKER',
        resume_builder: 'https://resume-builder.jobhack.co?utm_source=RESUME_CHECKER',
        terms: 'https://scoutout.co/terms-th/',
        privacy: 'https://scoutout.co/privacy-th/',
        salary_checker: 'https://salary.scoutout.co'
    },
    authen: {
        sso_url : "https://d2zjecrqpgwbzj.cloudfront.net",
        client_id : "LEARN",
        redirect_uri_result : "https://resume-checker-test.web.app/result",
        redirect_uri : "https://resume-checker-test.web.app",
        grant_type : "authorization_code",
        length : 32,
        api: {
            host_url: "https://mysatest.scoutout.net",
            token: "/api/v2.0/oauth/token",
            profile: "/api/v2.0/manager/profile",
            user_profile: "/api/v2.0/user/profile",
            refresh_token: "/api/v2.0/oauth/token"
        },
        rama: {
            host_url: "https://ramatest.scoutout.net",
            create_users: "/api/users",
            profile: "/api/me/profile",
            uploadFile: "/api/me/profile/files"
        }
    },
};


const prod = {
    ga_4: "G-MJY78XHH3T",
    gtm_id: "GTM-MDVT2FT",
    recaptcha_key: "6Lfs1wIkAAAAAFqYPpdgcAhxHp_EXA9zDs36-b3B",
    recaptcha_server_key: "6LfvrvAjAAAAANvdwwkHOef-9wOXodsSyODYXWjB",
    recaptcha_url: "https://www.google.com/recaptcha/api/siteverify",
    content_gen_url: "https://asia-southeast1-scoutout-mang-prod.cloudfunctions.net/contentgenerator/",
    upload_resume: {
        x_api_key: "0kz74WhgVda2JBHCGu7Qd3XSrhgRL5az146oon4w",
        base_url : "https://f3clcckfr7.execute-api.ap-southeast-1.amazonaws.com/production"
    },
    api: {
        x_api_key: "0kz74WhgVda2JBHCGu7Qd3XSrhgRL5az146oon4w",
        jobnow_plus: "https://plus.jobnow.work/api/jobs/search?q=",
        base_url: "https://dprod-api.scoutout.net/resume-checker"
    },
    firebase: {
        apiKey: "AIzaSyBZtAANzQO-IsrWT3Iwmj9i0_-5DjwONV8",
        authDomain: "scoutout-mang-prod.firebaseapp.com",
        databaseURL: "https://scoutout-mang-prod.firebaseio.com",
        projectId: "scoutout-mang-prod",
        storageBucket: "scoutout-mang-prod.appspot.com",
        messagingSenderId: "1045861674744",
        appId: "1:1045861674744:web:e3bf4ca8b3b6ecceab950d",
        measurementId: "G-ZB69LTZRJM"
    },
    app: {
        resume_checker: 'https://resume-checker.jobhack.co',
        jobrec_click_url: 'https://plus.jobnow.work/job/',
        jobhack: 'https://jobhack.co?utm_source=RESUME_CHECKER',
        oneprofile: 'https://oneprofile.me?utm_source=RESUME_CHECKER',
        jobhack_article: 'https://jobhack.co/careers?utm_source=RESUME_CHECKER',
        personality: 'https://quiz.scoutout.co?utm_source=RESUME_CHECKER',
        about_so: 'https://scoutout.co?utm_source=RESUME_CHECKER',
        resume_builder: 'https://resume-builder.jobhack.co?utm_source=RESUME_CHECKER',
        terms: 'https://scoutout.co/terms-th/',
        privacy: 'https://scoutout.co/privacy-th/',
        salary_checker: 'https://salary.scoutout.co'
    },
    authen: {
        sso_url : "https://account.scoutout.co",
        client_id : "LEARN",
        redirect_uri_result : "https://resume-checker.jobhack.co/result",
        redirect_uri : "https://resume-checker.jobhack.co",
        grant_type : "authorization_code",
        length : 32,
        api: {
            host_url: "https://account-api.scoutoutapi.com",
            token: "/api/v2.0/oauth/token",
            profile: "/api/v2.0/manager/profile",
            user_profile: "/api/v2.0/user/profile",
            refresh_token: "/api/v2.0/oauth/token"
        },
        rama: {
            host_url: "https://career-api.scoutoutapi.com",
            create_users: "/api/users",
            profile: "/api/me/profile",
            uploadFile: "/api/me/profile/files"
        }
    },
};

const config = process.env.REACT_APP_ENVIRONMENT === 'production'
    ? prod : process.env.REACT_APP_ENVIRONMENT === 'test' ? test
    : dev;

export default {
    // Add common config values here
    MAX_ATTACHMENT_SIZE: 5000000,
    ...config
};