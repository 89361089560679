import axios from "axios";
import { useNavigate } from "react-router-dom";
import TokenService from "./token.service";
import { OauthModel } from "./oauth.model";
import config from "../../config";
import storage from "./storage";



const instance = axios.create({
  baseURL: config.authen.rama.host_url,
  headers: {
    "Content-Type": "application/json",
  },
});

const instanceRefresh = axios.create({
  baseURL: config.authen.api.host_url,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      // config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
      config.headers["scoutout-authorization"] = token; // for Node.js Express back-end
    }
    return config;
  },
  (error) => {
    alert("กรุณา Login ใหม่");
    window.open('/authen')
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (originalConfig.url !== config.authen.api.token && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const rs = await instanceRefresh.post(config.authen.api.token, {
            grant_type: 'refresh_token',
            refresh_token: TokenService.getLocalRefreshToken(),
          });
          console.log(rs.data);
          const token = rs.data as OauthModel
          TokenService.updateLocalAccessToken(token.access_token);

          return instance(originalConfig);
        } catch (_error) {
        
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  }
);

export default instance;