
import JH_LOGO from "../../assets/images/jobhack-logo.png";
import JH_LOGO_MOBILE from "../../assets/images/jobhack-logo-mobile.png";
import "./header.scss";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import config from "../../config";
import { useContext, useEffect, useState } from "react";
import UtilityService from "../../utils/utilities";
import { useWindowWidth } from "@react-hook/window-size";
import { useLocation } from "react-router-dom";
import { SigninContext } from "../../context/signin.context";
import { ProfileRama } from "../../model/rama/profile-rama.model";
import PROFILE_IMG from "../../assets/images/profile-img.png";
import HistoryModal from "../../modal/history-modal";
import tokenService from "../../services/sso/token.service";
import GAConfig from "../../ga.config";

const HeaderComponent = () => {
    const location = useLocation();
    const [isHeader, setIsHeader] = useState(true);
    const width = useWindowWidth();
    const isMobile: Boolean = width < 768 ? true : false
    const isTablet: Boolean = width < 992 && width >= 768 ? true : false
    const [toggle, setToggle] = useState(false);
    const { profileModel }: any = useContext(SigninContext)
    const [profileMod, setProfileMod] = useState<ProfileRama>();
    const [showPopupHistory, setShowPopupHistory] = useState(false);
    useEffect(() => {


        try {
            if (location.pathname.includes("/techsauce")) {
                setIsHeader(false);
            }
        } catch {

        }

    }, [])

    useEffect(() => {

        if (profileModel) {
            const profileModels = profileModel as ProfileRama;
            setProfileMod(profileModels);
            console.log(profileModels.firstName)
        }
    }, [profileModel])
    
    const handlePopupHistory = () => {

        if(showPopupHistory){
            setShowPopupHistory(false);
        }else{
            setShowPopupHistory(true);
        }
    }

    const ToggleMenu = () => {
        if (toggle) {
            setToggle(false);
            document.body.style.overflow = 'auto';
        } else {
            setToggle(true);
            document.body.style.overflow = 'hidden';
        }

    }

    const clickMenu = (eventValue: string) => {
        UtilityService().clickSendEvent("click_"+ eventValue, GAConfig.event.click);
    }

    const LogOut = () => {

            tokenService.removeUser();
            window.open('/', '_self')
    }
    return (
        <>
          {
                    showPopupHistory &&
                    <HistoryModal showPopupHistory={showPopupHistory} handlePopupHistory={handlePopupHistory} ></HistoryModal>
                }
            {
                isHeader &&
                <div className="header">
                   
                    <Navbar expand="md">
                        <div className="container-control">
                            <Navbar.Brand href="/">
                                {
                                    isTablet || isMobile ? <img src={JH_LOGO_MOBILE} className="logo-so"></img> :

                                        <img src={JH_LOGO} className="logo-so"></img>
                                }

                            </Navbar.Brand>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={ToggleMenu} />
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="ml-auto">
                                    <Nav.Link href="/" onClick={() => clickMenu('หน้าหลัก')}>หน้าหลัก</Nav.Link>
                                    <Nav.Link href={config.app.jobhack} target="_blank" onClick={() => clickMenu('หางานสาย Tech')}>หางานสาย Tech</Nav.Link>
                                    <Nav.Link href={config.app.personality} target="_blank" onClick={() => clickMenu('ทดสอบบุคลิกภาพ')}>ทดสอบบุคลิกภาพ</Nav.Link>
                                    <Nav.Link href={config.app.jobhack_article} target="_blank" onClick={() => clickMenu('บทความ')}>บทความ</Nav.Link>
                                    <Nav.Link href={config.app.about_so} target="_blank" onClick={() => clickMenu('เกี่ยวกับเรา')}>เกี่ยวกับเรา</Nav.Link>
                                    {
                                        profileMod &&

                                        <NavDropdown title={
                                            <div className="nav-profile">
                                                <div className="d-flex">
                                                <img src={profileMod.profileImageUrl ? profileMod.profileImageUrl : PROFILE_IMG} className="nav-profile-img" ></img>
                                                <span className="nav-name">{profileMod.firstName}</span>
                                                </div>
                                            </div>
                                        } id="navbarScrollingDropdown" className="nav-profile">
                                            
                                            <NavDropdown.Item href="#action3" className="nav-dropdown-custom" onClick={handlePopupHistory}>ประวัติการตรวจสอบ</NavDropdown.Item>
                                            <NavDropdown.Item href="#action3" className="nav-dropdown-custom" onClick={LogOut}>ออกจากระบบ</NavDropdown.Item>
                                        </NavDropdown>
                                    }
                                       

                                        {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.2">
                                    Another action
                                </NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="#action/3.4">
                                    Separated link
                                </NavDropdown.Item>
                            </NavDropdown> */}
                                </Nav>
                            </Navbar.Collapse>
                        </div>
                    </Navbar>
                </div>
            }

        </>
    )
}

export default HeaderComponent;