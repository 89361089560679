import { CloseButton, Modal } from "react-bootstrap";
import "./history-modal.scss";
import { SigninContext } from "../context/signin.context";
import { useContext, useEffect, useState } from "react";
import { FileRama, ProfileRama } from "../model/rama/profile-rama.model";
import ApiServices from "../services/api.service";
import HistoryResultModel, { HistoryArray } from "../model/history.model";
import { Timestamp } from "firebase/firestore";
import DELETE_IMG from "../assets/images/delete.png";
import RamaService from "../services/sso/rama.service";
import { useLocation, useNavigate } from "react-router-dom";
import AnalyzeModel from "../model/analyze.model";

const HistoryModal = ({ showPopupHistory, handlePopupHistory }: any) => {
    const location = useLocation();
    const { profileModel, setProfileModel, analyzeModel, setAnalyzeModel }: any = useContext(SigninContext)
    const [profileMod, setProfileMod] = useState<ProfileRama>();
    const [historyResult, setHistoryResult] = useState<HistoryResultModel>();
    const navigate = useNavigate();
    useEffect(() => {
        if (profileModel) {
            console.log(profileModel);
            setProfileMod(profileModel)
            getResultHistory(profileModel)
        }
    }, [profileModel])

    const getResultHistory = (profileModel) => {

        ApiServices().getResult(profileModel.uuid).then((res) => {
            if (res.exists()) {
                const hisModel = res.data() as HistoryResultModel;
                setHistoryResult(hisModel);
                console.log(res.data())
            }
        })
    }

    const deleteHistory = (fileItem: HistoryArray) => {
        console.log(profileMod)
        if (profileMod) {

            profileMod.files.map((item: FileRama, index: number) => {

                if (item.fileKey == fileItem.key_path) {

                    profileMod.files.splice(index, 1);

                    // console.log(profileMod)
                    RamaService.updateProfile(profileMod).then((res) => {
                        setProfileModel(profileMod)
                    })
                    ApiServices().updateRemoveResult(fileItem.result_uid, profileMod.uuid, fileItem.file_name, fileItem.key_path, fileItem.created_at).then((res) => {
                        getResultHistory(profileMod)
                    })

                }
            })
        }
    }

    const getResultByUUid = (result_uuid) => {

        ApiServices().getResultUUID(result_uuid).then((res) => {
            console.log(res);

            const analyzeModel = res as AnalyzeModel;
            setAnalyzeModel(analyzeModel);
            handlePopupHistory();
            if (location.pathname.includes("/techsauce")) {
                
                navigate('/techsauce/result', { state: { result: analyzeModel, isLookingforJobs: true } });
            } else {
                navigate('/result', { state: { result: analyzeModel, isLookingforJobs: true } });
            }
        })

    }
    return (
        <>
            <div >
                <Modal
                    className="history-modal"
                    show={showPopupHistory}
                    onHide={handlePopupHistory}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title >   <span className="history-title">ประวัติการตรวจสอบ Resume ของคุณ </span></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div className="d-flex flex-column w-100">

                            {
                                (profileMod && historyResult && historyResult.results.length > 0) ?
                                    historyResult.results.map((items: HistoryArray, index: number) => {

                                        return (
                                            <div className="history-item-control" key={index}>
                                                <div className="history-item">
                                                    <span className="history-item-title">filename: {items.file_name}</span>
                                                    <span className="history-item-desc">Last checked: {new Date(items.created_at.toDate()).toLocaleDateString()}</span>
                                                    <span className="history-button-check" onClick={() => getResultByUUid(items.result_uid)}>ดูผลลัพธ์นี้</span>
                                                </div>
                                                <div className="d-flex flex-column">
                                                    <img src={DELETE_IMG} className="history-delete" onClick={() => deleteHistory(items)}></img>
                                                </div>
                                            </div>
                                        )
                                    }) :
                                    (profileMod && historyResult && historyResult.results.length == 0) &&
                                    <span className="history-no-result"> ไม่มีประวัติการตรวจสอบ </span>

                            }

                        </div>


                    </Modal.Body>

                </Modal>
            </div>
        </>
    )
}

export default HistoryModal;