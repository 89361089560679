import HeaderComponent from "../../components/header/header";
import "./result.scss";
import SHARE_IMG from "../../assets/images/share-img.png";
import MATCH_RATE_3 from "../../assets/images/match-rate-3.png";
import MATCH_RATE_2 from "../../assets/images/match-rate-2.png";
import MATCH_RATE_1 from "../../assets/images/match-rate-1.png";
import IMG_CHECK_SKILLS from "../../assets/images/check-skill.png";
import IMG_UNCHECK_SKILLS from "../../assets/images/need-skill.png";
import SHARE_FRIEND_IMG from "../../assets/images/share-friend.png";
import SUGGESTION_IMG from "../../assets/images/suggestion-img.png";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import AnalyzeModel, { Skills } from "../../model/analyze.model";
import LookingJobsModal from "../../modal/looking-jobs";
import SocialShareModal from "../../modal/social-share";
import FooterComponent from "../../components/footer/footer";
import config from "../../config";
import UtilityService from "../../utils/utilities";
import IMG_FLOATING from "../../assets/images/valentine-floating.png";
import "../../modal/floating-ads.scss";
import FOOTER_VALENTINE from "../../assets/images/footer-valentine.png";
import GAConfig from "../../ga.config";
import ApiServices from "../../services/api.service";
import AnalyticModel from "../../model/analytic.model";
import { Button } from "react-bootstrap";
import storage from "../../services/sso/storage";
import { SigninContext } from "../../context/signin.context";
import AuthFunction from "../../services/sso/auth-func";
import { OauthModel } from "../../services/sso/oauth.model";
import tokenService from "../../services/sso/token.service";
import RamaService from "../../services/sso/rama.service";
import { FileRama, ProfileRama } from "../../model/rama/profile-rama.model";
import HistoryModal from "../../modal/history-modal";
const ResultPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [resumeAnalyze, setResumeAnalyze] = useState<AnalyzeModel>();
    const [imgScore, setImgScore] = useState(MATCH_RATE_1);
    const [readmore, setReadmore] = useState(false);
    const [textHeaderSuggest, setTextHeaderSuggest] = useState("คำแนะนำพร้อมแล้ว! มาเริ่มกันเลย");
    const [showLookingjobPopup, setShowLookingjobPopup] = useState(false);
    const [statusLookingJobs, setStatusLookingJobs] = useState(false);
    const [showShare, setShowShare] = useState(false);
    const [urlShare, setUrlShare] = useState("");
    const [fileUpload, setFileUpload] = useState<File>();
    const [resultType, setResultType] = useState(0);
    const [ip, setIp] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const [code, setCode] = useState("");
    const [isAuthen, setIsAuthen] = useState(false);
    const { profileModel, analyzeModel }: any = useContext(SigninContext)
    const [profileMod, setProfileMod] = useState<ProfileRama>();
    
    const clickCheckResumeAgain = () => {
        UtilityService().clickSendEvent(GAConfig.event_name.click_check_again, GAConfig.event.click);
        navigate('/');
    }
   


    useEffect(() => {

        if (analyzeModel) {
            setResumeAnalyze(analyzeModel);
        }

    }, [analyzeModel])


    useEffect(() => {
        console.log("USEEFFECT")
        try {
            if (location.pathname.includes("/techsauce")) {

                UtilityService().setPageTitle("Result Page from Techsauce");
            } else {

                UtilityService().setPageTitle("Result Page");
            }
        } catch {

            UtilityService().setPageTitle("Result Page");
        }

        try {


            if (location.state.result) {
                UtilityService().clickSendEvent("complete_check_result", GAConfig.event.load);
                const resultObject: AnalyzeModel = location.state.result;
                const fileUpload = location.state.fileUpload as File;
                const file = DataURIToBlob(fileUpload["content"]);
                const profileMods = profileModel as ProfileRama;
                if (location.state.fileUpload) {
                    setFileUpload(fileUpload["content"]);
                    RamaService.uploadFile(file).then((res) => {
                        console.log(res.data);
                        const addFile: FileRama = {
                            fileKey: res.data[0],
                            fileName: fileUpload.name,
                            fileSize: fileUpload.size,
                        }
                        profileMods.files.push(addFile);
                        console.log(profileMods)
                        profileMods.filesDescription = "Resume from Resume Checker"
                        RamaService.updateProfile(profileMods).then((res2) => {
                            console.log(res2);

                            ApiServices().checkResult(profileMods.uuid).then((check) => {
                                if (check.exists()) {
                                    ApiServices().updateResult(resultObject.uuid, profileMods.uuid, fileUpload.name, res.data[0]).then((update) => {
                                     
                                    })
                                } else {
                                    ApiServices().saveResult(resultObject.uuid, profileMods.uuid, fileUpload.name, res.data[0]).then((res) => {
                                    })
                                }
                            })
                        })


                    })

                }


                setResumeAnalyze(resultObject)
                let eventValue: AnalyticModel = {
                    name: "Result Page"
                }
                if (location.pathname.includes("/techsauce")) {
                    eventValue.channel = "Techsauce"
                } else {
                    eventValue.channel = "/"
                }

                ApiServices().getIP().then((res) => {
                    console.log(res);
                    if (res.IPv4) {
                        eventValue.ip_address = res.IPv4;
                        setIp(res.IPv4)
                    }
                    eventValue.job_role = resultObject.input.job_title + "";
                    ApiServices().createCustomAnalytic("page_view", eventValue, "resume-checker");

                })
                UtilityService().clickSendEvent( resultObject.score + " : " + resultObject.input.job_title, GAConfig.event.load);
                if (resultObject.score < 55) {
                    setResultType(3);
                    setImgScore(MATCH_RATE_1)
                    setTextHeaderSuggest("คำแนะนำพร้อมแล้ว! มาเริ่มกันเลย")
                } else if (resultObject.score >= 55 && resultObject.score < 80) {
                    setResultType(2)
                    setImgScore(MATCH_RATE_2)
                    setTextHeaderSuggest("ปรับตามคำแนะนำอีกนิด ปังแน่นอน!")

                } else if (resultObject.score >= 80) {
                    setResultType(1);
                    setImgScore(MATCH_RATE_3)
                    setTextHeaderSuggest("ยินดีด้วย! เรซูเม่ของคุณอยุ่ในเกณฑ์ดีมาก!")
                }

                if (!location.state.isLookingforJobs) {
                    setTimeout(() => {
                        setShowLookingjobPopup(true);
                    }, 5000);
                }



            } else {
                navigate('/')
            }
        } catch {
            navigate('/')
        }

        console.log('i fire once');
    }, [])

    const DataURIToBlob = (dataURI: string) => {
        const splitDataURI = dataURI.split(',')
        const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
        const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

        console.log(mimeString);

        const ia = new Uint8Array(byteString.length)
        for (let i = 0; i < byteString.length; i++)
            ia[i] = byteString.charCodeAt(i)

        return new Blob([ia], { type: mimeString })
      }


    const getAccessToken = (code, codeVerifier) => {

        ApiServices().oauthToken(code, codeVerifier).then((res: OauthModel) => {
            if (res) {

                tokenService.setUser(res);
                navigate('/scoutconnect');
                getProfile();
            }
            console.log(res);
        })
    }
    const getProfile = () => {

        RamaService.getProfile().then((res) => {
            console.log(res);
            setIsAuthen(true);
        }).catch((err) => {
            navigate('/authen')
        })
    }
    const handlePopupLookingJobs = () => {
        UtilityService().clickSendEvent("close_popup_looking_for_jobs", GAConfig.event.click);
        setShowLookingjobPopup(false);
    }

    const handleStatusLookingJobs = () => {
        setStatusLookingJobs(false);
    }

    const handleCloseShare = () => {
        UtilityService().clickSendEvent("click_close_share",GAConfig.event.click);
        setShowShare(false);
    }
    const clickShare = () => {
        let url = "";
        if (resultType === 1) {
            url = config.app.resume_checker + "/result/result-1.html";
        } else if (resultType === 2) {
            url = config.app.resume_checker + "/result/result-2.html";
        } else {
            url = config.app.resume_checker + "/result/result-3.html";
        }
        UtilityService().clickSendEvent("click_share_" + url,GAConfig.event.click);
        setShowShare(true);
        setUrlShare(url);
    }

 

    const clickSalaryChecker = () => {
        UtilityService().clickSendEvent("click_open_salary_checker",GAConfig.event.click);
        window.open(config.app.salary_checker)
    }


    return (
        <>
            <div className="result-page ">
                <HeaderComponent></HeaderComponent>

                {
                    (showLookingjobPopup && resumeAnalyze) &&
                    <LookingJobsModal handleStatusLookingJobs={handleStatusLookingJobs} showPopup={showLookingjobPopup} handlePopup={handlePopupLookingJobs} uuid={resumeAnalyze?.uuid}></LookingJobsModal>
                }
                {
                    <SocialShareModal url={urlShare} isShow={showShare} handleClose={handleCloseShare} resultTypes={resultType}></SocialShareModal>
                }

                <div className="container container-control">

                    {
                        resumeAnalyze &&
                        < div className="content-control">
                            <span className="text-gray-top">ผลลัพธ์ของเรซูมเม่คุณคือ...</span>
                            <img src={SHARE_IMG} className="share-img" onClick={() => clickShare()}></img>

                            <div className="result-match-control">
                                <div className="result-match-rate-img-control">
                                    <img src={imgScore} className="result-match-rate-img">


                                    </img>
                                    <span className="result-match-rate-text">{resumeAnalyze.score}%</span>
                                </div>
                                <div className="result-match-rate-content-control">
                                    <span className="result-match-rate-text-blue">{textHeaderSuggest}</span>
                                    {
                                        resumeAnalyze.salary_suggestion &&
                                        <div className="d-flex flex-column">
                                            <span className="result-match-rate-position">เงินเดือนที่เหมาะสม {Number(resumeAnalyze.salary_suggestion.min).toLocaleString()} - {Number(resumeAnalyze.salary_suggestion.max).toLocaleString()} บาท</span>
                                            <span className="result-match-rate-description">หรือ เช็กความเหมาะสมของเงินเดือนปัจจุบันของคุณได้ <span className="result-match-link" onClick={clickSalaryChecker}>ที่นี่</span></span>
                                        </div>
                                    }
                                    <span className="result-match-rate-position">ตำแหน่งงาน: {resumeAnalyze.input.job_title}</span>
                                    {/* <span className="result-match-rate-description d-flex"> {
                                        (!readmore && resumeAnalyze.input.job_description.toString().length > 180) ?

                                            <div className="d-inline-block">
                                                {resumeAnalyze.input.job_description.toString().slice(0, 180) + "...."}
                                                <span className="read-more-text" onClick={clickReadmore}>read more</span>
                                            </div>
                                            :

                                            resumeAnalyze.input.job_description

                                    }</span> */}
                                </div>
                            </div>
                            {/* <div className=" footer-ads-result">
                            <img src={FOOTER_VALENTINE} className="footer-ads-result-img" onClick={clickFormCampaign}></img>
                            </div> */}
                            <img src={SUGGESTION_IMG} className="img-suggestion"></img>
                            
                            {
                                resumeAnalyze.cover_letter_suggestion &&
                                <div className="result-border-item">
                                 
                                    <span className="result-item-header">Cover letter แนะนำสำหรับคุณ</span>
                                    {/* <span className="result-item-desc">is simply dummy text of the printing and typesetting industry.
                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</span> */}
                                    <div className="result-line-divider"></div>

                                    <span className="result-grid-black-14-reg my-auto ">{resumeAnalyze.cover_letter_suggestion}</span>


                                </div>
                            }
                            
                            {
                                resumeAnalyze.interview_questions &&
                                <div className="result-border-item">
                                    <span className="result-item-header">คำถามที่คุณอาจถูกสัมภาษณ์ในตำแหน่งนี้</span>
                                    {/* <span className="result-item-desc">is simply dummy text of the printing and typesetting industry.
                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</span> */}
                                    <div className="result-line-divider"></div>
                                    {
                                        resumeAnalyze.interview_questions.map((item: string, index: number) => {


                                            return (
                                                <span className="result-grid-black-14-reg my-auto " key={index}>{index + 1}.  {item}</span>


                                            )
                                        })
                                    }



                                </div>
                            }


                            {
                                (resumeAnalyze.horoscope.employment && resumeAnalyze.horoscope.finance) &&
                                <div className="result-border-black-item">
                                    <span className="result-black-text-header">ดวงของคุณตอนนี้...</span>

                                    <div className="result-line-divider"></div>
                                    <div className="result-item-grid-detail">
                                        <span className="result-black-text-left">การงาน</span>
                                        <span className="result-black-text-left-reg">
                                            {resumeAnalyze.horoscope.employment}
                                        </span>
                                    </div>
                                    <div className="result-line-divider"></div>
                                    <div className="result-item-grid-detail">
                                        <span className="result-black-text-left">การเงิน</span>
                                        <span className="result-black-text-left-reg">
                                            {resumeAnalyze.horoscope.finance}
                                        </span>
                                    </div>


                                </div>

                            }
                            <span className="result-suggestion-header">คำแนะนำที่จะทำให้เรซูเม่ของคุณ Perfect มากยิ่งขึ้น!</span>
                            <div className="result-border-item">
                             
                                <span className="result-item-header">Skill</span>
                                {/* <span className="result-item-desc">is simply dummy text of the printing and typesetting industry.
                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</span> */}
                                <div className="result-line-divider"></div>

                                <div className="result-item-grid-detail">
                                    <span className="result-item-sub-header">Key skills and skills opportunities</span>
                                    <div className="result-grid-right-control">

                                        <span className="result-grid-gray-14">ทักษะที่จำเป็นที่ต้องใส่ในเรซูเม่คุณ และทักษะที่คุณต้องเพิ่มเข้ามา!</span>

                                        <div className="d-flex result-check-skill-control">
                                            <img src={IMG_CHECK_SKILLS} className="result-check-skill-img my-auto"></img>
                                            <span className="result-grid-black-14 my-auto ">{resumeAnalyze.skills.key_skills.length} Skills ที่คุณมี : </span>

                                            {resumeAnalyze.skills.key_skills.map((item: string, index: number) => {

                                                return (

                                                    <div className="result-tag-check-skill" key={index}>{item}</div>
                                                )
                                            })

                                            }
                                        </div>
                                        <div className="d-flex result-check-skill-control">
                                            <img src={IMG_UNCHECK_SKILLS} className="result-check-skill-img my-auto"></img>
                                            <span className="result-grid-red-14 my-auto ">{resumeAnalyze.skills.opportunity_skills.length} Skills ที่คุณขาด : </span>
                                            {resumeAnalyze.skills.opportunity_skills.map((item: string, index: number) => {
                                                return (
                                                    <div className="result-tag-need-skill" key={index}>{item}</div>
                                                )
                                            })

                                            }
                                        </div>
                                        <span className="result-grid-black-16">โดยรวมแล้วคุณเกือบจะมีครบทุกทักษะที่จำเป็นแล้ว <span className="result-grid-black-16-bold">เพิ่มอีกแค่ {resumeAnalyze.skills.opportunity_skills.length} skills</span> เรซูเม่คุณจะ Perfect แน่นอน!</span>
                                    </div>

                                </div>

                                <div className="result-line-divider"></div>
                                <div className="result-item-grid-detail">
                                    <span className="result-item-sub-header">Unused skills</span>
                                    <div className="result-grid-right-control">
                                        <span className="result-grid-gray-14">ทักษะที่คุณมี แต่ยังอาจยังไม่ได้ใช้สำหรับงานนี้</span>
                                        <div className="d-flex result-check-skill-control">
                                            {resumeAnalyze.skills.unused_skills.map((item: string, index: number) => {
                                                return (
                                                    <div className="result-tag-unused-skill" key={index}>{item}</div>
                                                )
                                            })

                                            }

                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="result-border-item">
                                <span className="result-item-header">Data</span>
                                {/* <span className="result-item-desc">is simply dummy text of the printing and typesetting industry.
                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</span> */}
                                <div className="result-line-divider"></div>

                                <div className="result-item-grid-detail">
                                    <span className="result-item-sub-header">เบอร์ติดต่อ</span>
                                    <div className="result-grid-right-control">

                                        <span className="result-grid-gray-14">เราตรวจพบหมายเลขโทรศัพท์ของคุณในเรซูเม่ คือ:</span>

                                        <div className="d-flex result-check-skill-control">
                                            <img src={
                                                resumeAnalyze.data.phone.is_passed ? IMG_CHECK_SKILLS : IMG_UNCHECK_SKILLS
                                            } className="result-check-skill-img my-auto"></img>
                                            <span className="result-grid-black-14 my-auto ">{resumeAnalyze.data.phone.value}</span>
                                        </div>
                                        <span className="result-grid-black-16">{resumeAnalyze.data.phone.description}</span>
                                    </div>

                                </div>

                                <div className="result-line-divider"></div>
                                <div className="result-item-grid-detail">
                                    <span className="result-item-sub-header">E-mail</span>
                                    <div className="result-grid-right-control">
                                        <span className="result-grid-gray-14">เราตรวจพบอีเมลของคุณในเรซูเม่ คือ:</span>

                                        <div className="d-flex result-check-skill-control">
                                            <img src={
                                                resumeAnalyze.data.email.is_passed ? IMG_CHECK_SKILLS : IMG_UNCHECK_SKILLS
                                            } className="result-check-skill-img my-auto"></img>
                                            <span className="result-grid-black-14 my-auto ">{resumeAnalyze.data.email.value}</span>
                                        </div>
                                        <span className="result-grid-black-16">{resumeAnalyze.data.email.description}</span>

                                    </div>
                                </div>
                            </div>

                            <div className="result-border-item">
                                <span className="result-item-header">Document</span>
                                {/* <span className="result-item-desc">is simply dummy text of the printing and typesetting industry.
                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</span> */}
                                <div className="result-line-divider"></div>

                                <div className="result-item-grid-detail">
                                    <span className="result-item-sub-header">Word count</span>
                                    <div className="result-grid-right-control">
                                        <div className="d-flex result-check-skill-control">
                                            <img src={
                                                resumeAnalyze.document.word_count.is_passed ? IMG_CHECK_SKILLS : IMG_UNCHECK_SKILLS
                                            } className="result-check-skill-img my-auto"></img>
                                            <span className="result-grid-black-14-reg my-auto ">เรซูเม่ของคุณมีคำจำนวนทั้งหมด  <span className="result-grid-black-16-bold">{resumeAnalyze.document.word_count.value} คำ</span></span>
                                        </div>
                                        <span className="result-grid-black-16">{resumeAnalyze.document.word_count.description}</span>
                                    </div>

                                </div>

                                <div className="result-line-divider"></div>
                                <div className="result-item-grid-detail">
                                    <span className="result-item-sub-header">File type</span>
                                    <div className="result-grid-right-control">
                                        <div className="d-flex result-check-skill-control">
                                            <img src={
                                                resumeAnalyze.document.file_extension.is_passed ? IMG_CHECK_SKILLS : IMG_UNCHECK_SKILLS
                                            } className="result-check-skill-img my-auto"></img>
                                            <span className="result-grid-black-14-reg my-auto ">ไฟล์เรซูเม่ของคุณคือ <span className="result-grid-black-16-bold">{resumeAnalyze.document.file_extension.value}</span></span>
                                        </div>
                                        <span className="result-grid-black-16">{resumeAnalyze.document.file_extension.description}</span>
                                    </div>
                                </div><div className="result-line-divider"></div>
                                <div className="result-item-grid-detail">
                                    <span className="result-item-sub-header">File name</span>
                                    <div className="result-grid-right-control">
                                        <div className="d-flex result-check-skill-control">
                                            <img src={
                                                resumeAnalyze.document.file_name.is_passed ? IMG_CHECK_SKILLS : IMG_UNCHECK_SKILLS
                                            } className="result-check-skill-img my-auto"></img>
                                            <span className="result-grid-black-14-reg my-auto ">ชื่อไฟล์เรซูเม่ของคุณคือ
                                                <span className="result-grid-black-16-bold"> {resumeAnalyze.document.file_name.value}</span></span>
                                        </div>
                                        <span className="result-grid-black-16">{resumeAnalyze.document.file_name.description}</span>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="container footer-ads-result">
                            <img src={FOOTER_VALENTINE} className="footer-ads-result-img"  onClick={clickFormCampaign}></img>
                            </div> */}
                            <div className="button-control">
                                <div className="button-control-sub">
                                    <button className="button-red" onClick={clickCheckResumeAgain}>เช็กเรซูเม่ของคุณอีกครั้ง</button>
                                    <img src={SHARE_FRIEND_IMG} className="my-auto img-share" onClick={() => clickShare()}></img>
                                </div>

                            </div>
                        </div>
                    }

                </div>
                {/* <div className="d-none d-md-flex floating-ads-control">

                    <img src={IMG_FLOATING} className="floating-img" onClick={() => clickCampaign('Valentine Campaign')} />

                </div> */}
                <FooterComponent></FooterComponent>
            </div>
        </>
    )
}

export default ResultPage;