import HeaderComponent from "../../components/header/header";
import RESUME_TOP_COVER from "../../assets/images/resume-top-cover.png";
import RESUME_TOP_COVER_MB from "../../assets/images/resume-top-cover-mb.png";
import ARROW_RED from "../../assets/images/arrow-red.png";
import BUTTON_CLOSE from "../../assets/images/close-button.png";
import RESUME_ICON from "../../assets/images/resume-icon.png";
import "./landing.scss";
import "./popup.scss";
import { Dropdown, Form, Modal, ProgressBar } from "react-bootstrap";
import React, { useContext, useEffect, useRef, useState } from "react";
import ApiServices from "../../services/api.service";
import JDSuggestionModel from "../../model/jd-suggestion.model";
import IMG_SECTION_2 from "../../assets/images/img-section-2.png";
import AnalyzeModel from "../../model/analyze.model";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import IMG_EDIT from "../../assets/images/edit-text.png";
import { useWindowWidth } from "@react-hook/window-size";
import ErrorModal from "../../modal/error-modal";
import FooterComponent from "../../components/footer/footer";
import config from "../../config";
import FloatingAdsComponent from "../../modal/floating-ads";
import UtilityService from "../../utils/utilities";
import GAConfig from "../../ga.config";
import UploadComponent from "../../components/upload/upload";
import AnalyticModel from "../../model/analytic.model";
import { SigninContext } from "../../context/signin.context";
import tokenService from "../../services/sso/token.service";
import { OauthModel } from "../../services/sso/oauth.model";
import RamaService from "../../services/sso/rama.service";
import storage from "../../services/sso/storage";
import AuthFunction from "../../services/sso/auth-func";
import MysaService from "../../services/sso/mysa.service";
import { UplaodResumeModel } from "../../model/upload-resume.model";
import PopupModal from "../../modal/popup";

const LandingPage = () => {
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const [showPopup, setPopup] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [fileUpload, setFileUpload] = useState();
    const [jobDesc, setJobDesc] = useState("");
    const [jdSuggestModel, setJdSuggestModel] = useState<JDSuggestionModel[]>();
    const [selectJobTitle, setSelectJobTitle] = useState(999);
    const [jobTitle, setJobTitle] = useState("");
    const [analyzeModel, setAnalyzeModel] = useState<AnalyzeModel>();
    const [lookingJobs, setLookingJobs] = useState("0");
    const [finishPost, setFinishPost] = useState(false);
    const [isEditJobTitle, setIsEditJobTitle] = useState(true);
    const width = useWindowWidth();
    const [ip, setIp] = useState("");
    const [code, setCode] = useState("");
    const [isAuthen, setIsAuthen] = useState(false);
    const { codeVerifier, setCodeVerifier, profileModel, setProfileModel }: any = useContext(SigninContext)
    const [showPopupAds, setShowPopupAds] = useState(true);
    let lookingJobsText = "";
    const others: JDSuggestionModel = {
        job_description: "",
        job_title: ""
    }

    useEffect(() => {
        try{
            let eventValue: AnalyticModel = {
                name: "Landing Page",
            }
            if (location.pathname.includes("/techsauce")) {
                eventValue.channel = "Techsauce";
                UtilityService().setPageTitle("Landing Page from Techsauce");
            } else {
                eventValue.channel = "/";
                UtilityService().setPageTitle("Landing Page");
            }

            ApiServices().getIP().then((res) => {
                console.log(res);
                if(res.IPv4){
                    eventValue.ip_address = res.IPv4;
                    setIp(res.IPv4)
                }
                ApiServices().createCustomAnalytic("page_view", eventValue, "resume-checker");
            })
           
        }catch{
            let eventValue: AnalyticModel = {
                name: "Landing Page",
                channel: "/"
            }
            ApiServices().getIP().then((res) => {
                console.log(res);
                if(res.IPv4){
                    eventValue.ip_address = res.IPv4;
                    setIp(res.IPv4)
                }
                ApiServices().createCustomAnalytic("page_view", eventValue, "resume-checker");
            })
           
            UtilityService().setPageTitle("Landing Page");
        }
      
    }, [])

    useEffect(() => {
        if (searchParams.get('code') && searchParams.get('state')) {
            setCode(searchParams.get('code') + "")
            setCodeVerifier(localStorage.getItem(storage.code_verifier)!.toString())
            getAccessToken(searchParams.get('code'), localStorage.getItem(storage.code_verifier)!.toString());
        }else{
            if(tokenService.getLocalAccessToken()){
                getProfile();
            }
        }
    },[])
    useEffect(() => {
        console.log(lookingJobs);
    }, [lookingJobs])

    useEffect(() => {
        console.log(showPopup);
    }, [showPopup])
    useEffect(() => {
       
        if (finishPost && analyzeModel) {
            if (lookingJobs != "0") {
                ApiServices().postResultUpdate(analyzeModel.uuid, lookingJobs).then((res: any) => {
                    console.log(res);
                    if (location.pathname.includes("/techsauce")) {
                        navigate('/techsauce/result', { state: { result: analyzeModel, isLookingforJobs: true } });
                    } else {
                        navigate('/result', { state: { result: analyzeModel, isLookingforJobs: true } });
                    }

                })
            } else {
                if (location.pathname.includes("/techsauce")) {
                    navigate('/techsauce/result', { state: { result: analyzeModel, isLookingforJobs: false } });
                } else {
                    navigate('/result', { state: { result: analyzeModel, isLookingforJobs: false } });
                }
            }
        }
    }, [finishPost])

    const handleClosePopupAds = () => {
        setShowPopupAds(false);
    }
    const togglePopup = () => {

        if (showPopup) {
            setPopup(false);
            setFileUpload(undefined);
            console.log("Remove popup")
            console.log(fileUpload)
        } else {
            setPopup(true);
        }

    }

    const oneprofileClick = () => {

        window.open(config.app.oneprofile, "_blank");
    }
    const handleClick = (event: any) => {
        setPopup(true)
        setCurrentStep(1);
    }
    const getAccessToken = (code, codeVerifier) => {

        ApiServices().oauthToken(code, codeVerifier).then((res: OauthModel) => {
            if (res) {

                tokenService.setUser(res);
                 navigate('/');
                 getProfile();
                //  setPopup(true);
                 setCurrentStep(1);
            }
            console.log(res);
        })
    }
    const getProfile = () => {
       
        RamaService.getProfile().then((res) => {
            if(res.data){
                setProfileModel(res.data);
            }
            setIsAuthen(true);
        }).catch((err) => {
            if(err.response.data.message == "User not found"){
                RamaService.createProfile().then((res) => {
                    getProfile();
                })
            }else{
                navigate('/')
            }
          
        })
    }
    const postResult = async () => {

        console.log(fileUpload)
        if (fileUpload && jobDesc && jobTitle) {
            setCurrentStep(3);
            try {
                const uploadResult = await ApiServices().uploadResume(fileUpload, fileUpload["name"]);
                if(uploadResult){
                    const resumeResult = uploadResult as UplaodResumeModel;
                    const inputObject  = {
                        job_description : jobDesc,
                        job_title: jobTitle,
                        resume_key: resumeResult.resume_key
                    }
                    const response = await ApiServices().postResult(inputObject)
                    console.log(response);
                    if (response.status === 200) {
                        const result = await response.json();
                        console.log(result);
                        setFinishPost(true);
                        setAnalyzeModel(result);
                        updateValue();
                    } else {
    
                        setShowErrorPopup(true);
                    }
                }
              
               
            } catch {
                setShowErrorPopup(true);
                // setPopup(false);
            }

        }

    }

    const updateValue = () => {
        console.log(lookingJobs);
    }


    const handleErrorPopup = () => {
      
        UtilityService().clickSendEvent("handle_popup_error", GAConfig.event.load);
        setShowErrorPopup(false);
    }

    const HandleTryagain = () => {
        UtilityService().clickSendEvent("handle_popup_tryagain", GAConfig.event.click);
        postResult();
        setShowErrorPopup(false);
    }

    const clickCreateResume = () => {
        UtilityService().clickSendEvent("open_resume_builder", GAConfig.event.click);
        window.open(config.app.resume_builder, "_blank");
    }
    return (

        <>
            <div className="d-flex flex-column landing">
                {
                    showErrorPopup && <ErrorModal showPopup={showErrorPopup} handlePopup={handleErrorPopup} message={"Something went wrong, Please try again later."} HandleTryagain={HandleTryagain}></ErrorModal>
                }

                <HeaderComponent></HeaderComponent>
                <div className="landing-container">
                    <div className="landing-1-grid-control">

                        <div className="landing-1-left-control">
                            <img src={RESUME_TOP_COVER_MB} className="d-md-none d-flex landing-1-right-img"></img>
                            <h1 className="landing-1-left-header">Resume ของคุณ</h1>
                            <h2 className="landing-1-left-sub-header">
                                มีคุณภาพดีพอแล้วหรือยัง?</h2>
                            <span className="landing-1-left-desc">
                                ให้เราช่วยตรวจสอบและปรับเรซูเม่ของคุณ
                                ให้เหมาะสมและมีคุณภาพ เพื่อดึงดูดผู้ว่าจ้าง
                                สร้างโอกาส สร้างงานที่มั่นคง
                            </span>

                            <div className="d-flex button-primary-control">
                                <button className="button-primary" onClick={handleClick}>เริ่มตรวจสอบเรซูเม่</button>

                                <h3 className="resume-builder-text" onClick={clickCreateResume}>
                                    หรือ สร้างเรซูเม่ด้วย <span className="resume-builder-text-special">Resume Builder</span> ฟรี!
                                </h3>
                              
                            </div>

                         
                            {/* <span className="landing-1-left-warn-gray">เมื่อกดปุ่ม 'อัปโหลดเรซูเม่' ถือว่าฉันได้อ่านและยอมรับ <a href="" className="landing-1-left-warn-link">ข้อกําหนด/เงื่อนไขการใช้งาน</a> และ <a href="" className="landing-1-left-warn-link">นโยบายความเป็นส่วนตัว</a></span> */}

                        </div>
                        <img src={RESUME_TOP_COVER} className="d-md-flex d-none landing-1-right-img"></img>
                    </div>
                    <div className="landing-2-control">
                        <h3 className="landing-2-blue-header">เติมส่วนที่ขาด ตัดสิ่งที่ไม่จำเป็น</h3>
                        <h3 className="landing-2-black-header">ให้เรซูเม่ของคุณโดดเด่นกว่าใคร</h3>
                        <div className="landing-2-grid-control">
                            <div className="landing-2-left-control">

                                <img src={IMG_SECTION_2} className="landing-2-left-img"></img>
                            </div>
                            <div className="landing-2-right-control">
                                <div className="landing-2-right-text">ตรวจสอบให้แน่ใจว่าเรซูเม่ของคุณโดดเด่นและมีคุณภาพ</div>
                                <ul className="landing-2-right-li">

                                    <li>ตรวจสอบคำสะกดที่ผิดพลาดให้ถูกต้อง</li>
                                    <li>ตัด Skill ที่ไม่จำเป็น เสริม Skill หลัก ที่เหมาะสมกับตำแหน่งงาน</li>
                                    <li>จัดระเบียบซูเม่ให้ดึงดูดสายตา ไม่รก หรือแน่นเกินไป</li>
                                    <li>ตรวจสอบด้วยระบบ AI แสดงผลเป็นคะแนน เข้าใจง่าย ชัดเจน</li>
                                    <li>ได้รับโอกาสในการทำงาน หรือสายงานที่เกี่ยวข้อง</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="landing-3-control">
                    <div className="container d-flex flex-column">
                        <span className="landing-3-black-header">จากประสบการณ์การตรวจสอบเรซูเม่กว่า</span>
                        <span className="landing-3-red-header">10,000 ฉบับของระบบเรา</span>
                        <span className="landing-3-sub-black-header">
                            รวมถึงความคิดเห็นจากนายจ้างส่วนใหญ่
                        </span>
                        <span className="landing-3-sub-black-header">
                            นี่คือ <span className="landing-3-sub-red-header">ตัวอย่างข้อผิดพลาด</span>ที่ถูกค้นพบ
                        </span>

                        <div className="landing-3-grid-control">
                            <ul className="landing-3-right-li">

                                <li>เรซูเม่ที่ใช้คำซ้ำๆ คำศัพท์เฉพาะมากเกินไป</li>
                                <li>ใส่ข้อมูลจนแน่นไปหมด แต่ไม่เหมาะสมกับตำแหน่งงาน</li>
                                <li>ระบุแต่ความรับผิดชอบ แต่ไม่ระบุความสำเร็จในการทำงาน</li>
                                <li>ใช้ ฟอนต์ ตัวอักษรที่อ่านยาก สะกดคำผิด</li>
                                <li>ขาดประสบการณ์ที่เกี่ยวข้องกับตำแหน่ง</li>
                            </ul>
                            <ul className="landing-3-right-li">

                                <li>ใส่ข้อมูลการติดต่อผิด พลาดโอกาส
                                    ถูกเรียกสัมภาษณ์</li>
                                <li>ร่ายประวัติยาวเหยียดตั้งแต่เกิด อนุบาล
                                    ประถม มัธยม</li>
                                <li>ขาดวัตถุประสงค์ที่ชัดเจนว่าเราต้องการ
                                    ทำงานอะไร หน้าที่อะไร</li>
                                <li>รูปเซลฟี่ แอ๊บแบ๊ว คิ้วๆ</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="landing-4-control">

                    <div className="container d-flex flex-column">
                        <span className="landing-4-header">ประเมินองค์ประกอบทั้งหมดในเรซูเม่ของคุณ <br className="d-none d-md-block"></br>เพื่อระบุสิ่งที่คุณทำได้ถูกต้องและแนะนำในสิ่งที่ต้องปรับปรุง</span>

                        <button className="landing-4-button" onClick={handleClick}>อัปโหลดเรซูเม่</button>
                    </div>
                </div>
                <FooterComponent></FooterComponent>
            </div>

            <Modal
                show={showPopup}
                onHide={() => togglePopup}
                backdrop="static"
                dialogClassName="modal-dialog-popups"
                keyboard={false}
            >

                <Modal.Body>
                    <img src={BUTTON_CLOSE} className="button-close-popup" onClick={togglePopup}></img>
                    <UploadComponent  isInternal={true}></UploadComponent>

                </Modal.Body>

            </Modal>
            {/* {
                showPopupAds &&   <PopupModal showMod={showPopupAds} handleClose={handleClosePopupAds} />
            } */}
            {/* <FloatingAdsComponent></FloatingAdsComponent> */}
        </>
    )
}
export default LandingPage;
