import { Button, Form, FormCheck } from "react-bootstrap";
import "./login.scss";
import { useContext, useEffect, useState } from "react";
import AuthFunction from "../../services/sso/auth-func";
import storage from "../../services/sso/storage";
import config from "../../config";
import { SigninContext } from "../../context/signin.context";
import { ProfileRama } from "../../model/rama/profile-rama.model";
import PROFILE_IMG from "../../assets/images/profile-img.png";
import { useFilePicker } from 'use-file-picker';
const LoginComponent = ({ handleChangeFile }: any) => {

    let inputRef: any;
    const [checkTerm, setCheckTerm] = useState(false);
    const [isError, setIsError] = useState(false);
    const { profileModel }: any = useContext(SigninContext)
    const [profileMod, setProfileMod] = useState<ProfileRama>();

    const clickUpload = () => {
        
        if(!checkTerm ){
            setIsError(true);
        }else{
            openFileSelector();
        }
    }
    const [openFileSelector, { filesContent, loading }] = useFilePicker({
        multiple: false,
        readAs: 'DataURL', // availible formats: "Text" | "BinaryString" | "ArrayBuffer" | "DataURL"
        // accept: '.ics,.pdf',
        accept: ['.json', '.pdf', 'image/*'],
        limitFilesConfig: { min: 0, max: 3 },
        onFilesSelected: ({ plainFiles, filesContent, errors }) => {
            // this callback is always called, even if there are errors
            // setFileUpload(filesContent)

            console.log(plainFiles[0]);
            // console.log('onFilesSelected', plainFiles, filesContent, errors);
        },
    });
    useEffect(() => {
        if (filesContent.length > 0) {
            handleChangeFile(filesContent[0]);
            // setFileUpload(filesContent[0])
        }
    }, [filesContent])

    useEffect(() => {

        if (profileModel) {
            setProfileMod(profileModel);
        }

    }, [profileModel])
    const handleChange = (event: any) => {
        console.log("Change");
        const fileUploaded = event.target.files[0];

        if (fileUploaded) {
            handleChangeFile(fileUploaded);
        }


    };

    const handleChangeCheck = (e) => {
        console.log(e.target.checked);
        setIsError(false);
        if (e.target.checked) {
            setCheckTerm(true);
        } else {
            setCheckTerm(false);
        }
    }
    const handleUploadResumeNow = () => {

        if (profileMod) {
            console.log("Upload file")
            inputRef.click();
        } else if (checkTerm && !profileMod) {
            console.log("Upload file")
            inputRef.click();
        } else {
            setIsError(true);
        }

    }

    const authenticateUrl = () => {
        if (checkTerm) {
            const codeVerifier = AuthFunction().randomString(56);
            const codeChallenge = AuthFunction().PKCEencoding(codeVerifier);
            localStorage.setItem(storage.code_verifier, codeVerifier);
            const state = AuthFunction().randomString(config.authen.length);
            const urlReturn = config.authen.sso_url + "?" + new URLSearchParams({
                redirect_uri: config.authen.redirect_uri,
                state: state,
                code_challenge: codeChallenge,
                response_type: 'code',
                code_challenge_method: 'S256',
                client_id: config.authen.client_id
            })
            console.log(urlReturn)
            window.open(urlReturn, "_self");
        } else {
            setIsError(true);
        }
    }

    const clickLink = (url) => {

        window.open(url, "_blank");
    }
    return (

        <>
            <div className="login my-auto">

                <div className="login-control">{

                    profileMod ? <div className="profile-control">

                        <span className="login-header">ยินดีต้อนรับ</span>
                        <img src={profileMod.profileImageUrl ? profileMod.profileImageUrl : PROFILE_IMG} className="profile-img"></img>
                        <span className="profile-name">{profileMod.firstName} {profileMod.lastName}</span>
                        {/* <Form.Group className="terms-control" controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" onChange={(e) => handleChangeCheck(e)} className={isError ? "login-header-check-red terms-check" : "login-header-check terms-check"}/>
                        <span className={isError ? "terms-text terms-text-red" : "terms-text"}>ยินยอมว่าฉันได้อ่านและยอมรับ 
                                <span className={isError ? "terms-text-link terms-text-red" : "terms-text-link"}  onClick={() => clickLink(config.app.terms)}>ข้อกําหนด/เงื่อนไขการใช้งาน</span>และ 
                                <span className={isError ? "terms-text-link terms-text-red" : "terms-text-link"}  onClick={() => clickLink(config.app.privacy)}>นโยบายความเป็นส่วนตัว</span></span>
                        </Form.Group> */}
                        <Button className="profile-button-upload" onClick={() => openFileSelector()}>เริ่ม อัปโหลดเรซูเม่</Button>

                        <input
                            type="file"
                            ref={refParam => inputRef = refParam}
                            accept="image/png, image/jpeg, image/jpg, application/pdf, .doc, .docx"
                            onChange={handleChange}
                            style={{ display: 'none' }}
                        />
                        <span className="login-header-desc">อัปโหลดเรซูเม่ของคุณเป็นไฟล์ .pdf, .docx, .jpeg หรือ .png </span>

                    </div> : <>
                        <span className="login-header">ลงทะเบียน หรือ เข้าสู่ระบบ</span>
                        <span className="login-header-desc">สำหรับผู้ใช้งานที่ต้องการบันทึกข้อมูลการอัปโหลด และ แสดงผลลัพธ์ไว้ใน Profile ของท่าน และสะดวกในการใช้งานครั้งถัดไปในการดูผลลัพธ์ที่เคยบันทึกไว้แล้ว</span>
                        <Button className="login-button-mysa" onClick={authenticateUrl}>ลงทะเบียน / เข้าสู่ระบบ</Button>
                        <span className="login-header-or">หรือ</span>
                        <span className="login-header">การใช้งานแบบทันที</span>
                        <span className="login-header-desc">สำหรับผู้ใช้งานที่ต้องการตรวจสอบข้อมูลอย่างรวดเร็ว และไม่บันทึกผลลัพธ์เพื่อแสดงในการใช้งานครั้งถัดไป</span>

                        <Button className="login-button-upload" onClick={() => clickUpload()}>เริ่ม อัปโหลดเรซูเม่</Button>

                        <input
                            type="file"
                            ref={refParam => inputRef = refParam}
                            accept="image/png, image/jpeg, image/jpg, application/pdf, .doc, .docx"
                            onChange={handleChange}
                            style={{ display: 'none' }}
                        />
                        <span className="login-header-desc">อัปโหลดเรซูเม่ของคุณเป็นไฟล์ .pdf, .docx, .jpeg หรือ .png </span>
                        <Form.Group className="terms-control" controlId="formBasicCheckbox">
                            <Form.Check type="checkbox" onChange={(e) => handleChangeCheck(e)} className={isError ? "login-header-check-red terms-check" : "login-header-check terms-check"} />
                            <span className={isError ? "terms-text terms-text-red" : "terms-text"}>ยินยอมว่าฉันได้อ่านและยอมรับ
                                <span className={isError ? "terms-text-link terms-text-red" : "terms-text-link"} onClick={() => clickLink(config.app.terms)}>ข้อกําหนด/เงื่อนไขการใช้งาน</span>และ
                                <span className={isError ? "terms-text-link terms-text-red" : "terms-text-link"} onClick={() => clickLink(config.app.privacy)}>นโยบายความเป็นส่วนตัว</span></span>
                        </Form.Group>
                    </>
                }

                </div>


            </div>
        </>
    )
}

export default LoginComponent;