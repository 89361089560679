import config from "../../config";
import instance from "./auth-context.service";



const getProfile = () => {
    return instance.get(config.authen.rama.profile);
  };

  const uploadFile = (fileUpload) => {
    return instance.post(config.authen.rama.uploadFile, {
        files: fileUpload
    }, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
  };
  const updateProfile = (profileModel) => {
    return instance.put(config.authen.rama.profile, profileModel, {
        headers: {
          "Content-Type": "application/json",
        },
      });
  };
  const createProfile = () => {
    return instance.post(config.authen.rama.create_users, {
        headers: {
          "Content-Type": "application/json",
        },
      });
  };
 
  
  const RamaService = {
    getProfile,
    uploadFile,
    updateProfile,
    createProfile
  };
  
  export default RamaService;