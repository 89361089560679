import { Modal } from "react-bootstrap"
import Lottie from "lottie-react";
import "./looking-jobs.scss";
import BUTTON_CLOSE from "../assets/images/close-button.png";
import ApiServices from "../services/api.service";
const LookingJobsModal = ({ showPopup, handlePopup, uuid, handleStatusLookingJobs }: any) => {


    const handleErrorClick = () => {
        handlePopup();
    }

    const lookingForjobClick = (msg: string) => {
        ApiServices().postResultUpdate(uuid, msg).then((res: any) => {
            console.log(res);
            handleStatusLookingJobs();
            handlePopup();
        })
    }

    return (

        <div className="modal-looking-jobs">
            <Modal
                className="modal-size-looking-jobs"
                show={showPopup}
                onHide={handlePopup}
                backdrop="static"
                dialogClassName="modal-dialog-looking-jobs"
                keyboard={false}
            >

                <Modal.Body className="modal-looking-jobs">

                    <div className="d-flex flex-column mx-auto ">
                        <img src={BUTTON_CLOSE} className="modal-close-error" onClick={handleErrorClick}></img>
                        <div className="popup-looking-jobs-control">
                            <div className="d-flex flex-column">
                                <span className="text-jobs">คุณกำลัง หางานอยู่หรือเปล่า?</span>
                                <button className="button-jobs mx-auto" onClick={() => lookingForjobClick("NEEDING_URGENT_EMPLOYMENT")}>กำลังหางานอยู่</button>
                                <button className="button-jobs mx-auto" onClick={() => lookingForjobClick("SEARCHING_INTERESTING_JOB")}>ก็เปิดรับโอกาสใหม่ๆ</button>
                                <button className="button-jobs mx-auto" onClick={() => lookingForjobClick("NOT_LOOKING_FOR_JOB")}>ไม่ได้หางานอยู่นะ</button>
                            </div>

                        </div>
                    </div>

                </Modal.Body>

            </Modal>
        </div>
    )
}

export default LookingJobsModal;