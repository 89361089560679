import { Form, ProgressBar } from "react-bootstrap";
import "./upload.scss";
import BUTTON_CLOSE from "../../assets/images/close-button.png";
import { useEffect, useState } from "react";
import GAConfig from "../../ga.config";
import UtilityService from "../../utils/utilities";
import IMG_EDIT from "../../assets/images/edit-text.png";
import { useWindowWidth } from "@react-hook/window-size";
import JDSuggestionModel from "../../model/jd-suggestion.model";
import ApiServices from "../../services/api.service";
import AnalyzeModel, { InputModel } from "../../model/analyze.model";
import RESUME_ICON from "../../assets/images/resume-icon.png";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import LoginComponent from "../login/login";
import AuthFunction from "../../services/sso/auth-func";
import config from "../../config";
import storage from "../../services/sso/storage";
import { UplaodResumeModel } from "../../model/upload-resume.model";
const UploadComponent = ({ isInternal }: any) => {
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState(1);
    const [jobTitle, setJobTitle] = useState("");
    const [isEditJobTitle, setIsEditJobTitle] = useState(true);
    const [jdSuggestModel, setJdSuggestModel] = useState<JDSuggestionModel[]>();
    const [selectJobTitle, setSelectJobTitle] = useState(999);
    const [lookingJobs, setLookingJobs] = useState("0");
    const [analyzeModel, setAnalyzeModel] = useState<AnalyzeModel>();
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [jobDesc, setJobDesc] = useState("");
    const [finishPost, setFinishPost] = useState(false);
    const [fileUpload, setFileUpload] = useState<any>();
    const width = useWindowWidth();
    const isMobile: Boolean = width < 768 ? true : false
    const isTablet: Boolean = width < 992 && width >= 768 ? true : false
    const isDesktop: Boolean = width >= 992 ? true : false
    let lookingJobsText = "";
    const others: JDSuggestionModel = {
        job_description: "",
        job_title: ""
    }

    useEffect(() => {
        if (isInternal) {
            getJDSuggestion();
            setCurrentStep(1);
        }
    }, [isInternal])

    

    useEffect(() => {
      
        if (finishPost && analyzeModel) {
            if (lookingJobs != "0") {
                ApiServices().postResultUpdate(analyzeModel.uuid, lookingJobs).then((res: any) => {
                    console.log(res);
                    if (location.pathname.includes("/techsauce")) {
                        navigate('/techsauce/result', { state: { result: analyzeModel, isLookingforJobs: true, fileUpload: fileUpload } });
                    }else{
                        navigate('/result', { state: { result: analyzeModel, isLookingforJobs: true, fileUpload: fileUpload } });
                    }
                   
                })
            } else {
                if (location.pathname.includes("/techsauce")) {
                navigate('/techsauce/result', { state: { result: analyzeModel, isLookingforJobs: false, fileUpload: fileUpload } });
                }else{
                    navigate('/result', { state: { result: analyzeModel, isLookingforJobs: false, fileUpload: fileUpload } });
                }
            }
        }
    }, [finishPost])

    useEffect(() => {
        try{
            if (location.pathname.includes("/techsauce")) {
                UtilityService().clickSendEvent("channel_techsauce", GAConfig.event.channel)
            }
        }catch{

        }
      
    }, [])


    const getJDSuggestion = () => {

        ApiServices().getJDSuggestion().then((res) => {
            console.log(res);

            const jdResult = res as JDSuggestionModel[];

            setJdSuggestModel(jdResult);
        })
    }
 // Call a function (passed as a prop from the parent component)
    // to handle the user-selected file 
    const handleChangeFile = (fileUpload) => {
       
        if (fileUpload) {
            setFileUpload(fileUpload);
            setCurrentStep(2);
            
        }
    };

 

    const editJobTitle = () => {
        UtilityService().clickSendEvent(GAConfig.event_name.click_edit_job_title, GAConfig.event.click);
        setIsEditJobTitle(true);
    }

    const clickJDSuggest = (item: JDSuggestionModel, index: number) => {
        UtilityService().clickSendEvent(GAConfig.event_name.click_step_2_jobtitle_is + item.job_title, GAConfig.event.click);
        setJobDesc(item.job_description);
        setIsEditJobTitle(false);
        setSelectJobTitle(index);
        if (index === 888) {
            setJobTitle("");
            setJobDesc(item.job_description);
            setSelectJobTitle(index);
        } else {
            setJobDesc(item.job_description);
            setSelectJobTitle(index);
            setJobTitle(item.job_title);
        }



    }

    const onTextChange = (e: any) => {

        console.log(e.target.value);
        setJobDesc(e.target.value);
    }

    const onTextJobTitleChange = (e: any) => {

        console.log(e.target.value);
        setJobTitle(e.target.value);
    }

    const clickSubmit = () => {
        try{
            if (location.pathname.includes("/techsauce")) {
                UtilityService().clickSendEvent("channel_techsauce_submit", GAConfig.event.click)
            }else{
                UtilityService().clickSendEvent( "submit", GAConfig.event.click);
            }
        }catch{

        }
       
        postResult();
    }

    const clickLookingJobs = async (lkString: string) => {
        UtilityService().clickSendEvent("looking_for_jobs_" + lkString, GAConfig.event.click);
        console.log(lkString);
        setLookingJobs(lkString);
        lookingJobsText = lkString;
    }

    const postResult = async () => {

        if (fileUpload && jobDesc && jobTitle) {
            setCurrentStep(3);

            try {
                if (location.pathname.includes("/techsauce")) {
                    UtilityService().clickSendEvent("channel_techsauce_post_" + jobTitle, GAConfig.event.click)
                }else{
                    UtilityService().clickSendEvent("post_" + jobTitle, GAConfig.event.click)
                }
                const file = DataURIToBlob(fileUpload["content"]);
                try{
                    const uploadResult = await ApiServices().uploadResume(file, fileUpload.name);
                    if(uploadResult){
                        const resultResume = uploadResult as UplaodResumeModel;
                        const inputObject  = {
                            job_description : jobDesc,
                            job_title: jobTitle,
                            resume_key: resultResume.resume_key
                        }
                        const response = await ApiServices().postResult(inputObject)
                        setFinishPost(true);
                        setAnalyzeModel(response);
                        updateValue();
                    }
                  
                }catch{
                    setShowErrorPopup(true);
                }
            } catch {
                setShowErrorPopup(true);
                // setPopup(false);
            }

        }

    }
    const DataURIToBlob = (dataURI: string) => {
        const splitDataURI = dataURI.split(',')
        const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
        const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

        console.log(mimeString);

        const ia = new Uint8Array(byteString.length)
        for (let i = 0; i < byteString.length; i++)
            ia[i] = byteString.charCodeAt(i)

        return new Blob([ia], { type: mimeString })
      }


    const updateValue = () => {
        console.log(lookingJobs);
    }

   
    return (

        <>
            <div className="d-flex flex-column popup-control">
                <div className="popup-step-control">
                    <button className={
                        currentStep >= 1 ?
                            "popup-number-control-active" : "popup-number-control"

                    }  >1</button>
                    <div className={
                        currentStep >= 2 ?
                            "popup-line-step-active" : "popup-line-step"

                    }></div>
                    <button className={
                        currentStep >= 2 ?
                            "popup-number-control-active" : "popup-number-control"

                    } >2</button>
                    <div className={
                        currentStep >= 3 ?
                            "popup-line-step-active" : "popup-line-step"

                    }></div>
                    <button className={
                        currentStep == 3 ?
                            "popup-number-control-active" : "popup-number-control"

                    } >3</button>
                </div>

                {

                    currentStep == 2 ?
                        <div className="d-flex flex-column">
                            <span className="popup-text-header">กรอกข้อมูลเพิ่มเล็กน้อย</span>
                            <div className="popup-grid-control">

                                <div className="popup-choose-job-control d-flex flex-column">
                                    <div className="text-header-control d-flex flex-column">
                                        <span>1. เลือกตำแหน่งงานของคุณ</span>
                                        {
                                            !isEditJobTitle &&
                                            <div className="d-md-none d-flex popup-select-jobtitle-control" onClick={editJobTitle}>
                                                <span className="popup-select-jobtitle">{jobTitle}</span>
                                                <img src={IMG_EDIT} className="popup-select-jobtitle-icon"></img>
                                            </div>
                                        }

                                    </div>
                                    {
                                        (isEditJobTitle && isMobile) ?
                                            <JobTitleComponent jdSuggest={jdSuggestModel} clickJDSuggest={clickJDSuggest}
                                                selectJobTitle={selectJobTitle} others={others}></JobTitleComponent> :
                                            isDesktop || isTablet ?
                                                <JobTitleComponent jdSuggest={jdSuggestModel} clickJDSuggest={clickJDSuggest}
                                                    selectJobTitle={selectJobTitle} others={others}></JobTitleComponent> : <></>
                                    }


                                </div>
                                {/* <div className="popup-text-or-control"> หรือ </div> */}
                                <div className="d-flex flex-column popup-right-control">
                                    <div className="popup-jd-control">
                                        <div className="text-header-control">
                                            <span>2. กรอกรายละเอียดงานที่คุณต้องการสมัคร</span>
                                        </div>
                                        <Form.Control as="textarea" rows={3} value={jobDesc} onChange={(e) => onTextChange(e)}
                                            className="popup-text-area"
                                            placeholder="Job description ของงานที่คุณต้องการตรวจสอบ..." />
                                    </div>
                                    {
                                        selectJobTitle === 888 &&
                                        <Form.Control type="email" placeholder="ชื่อตำแหน่งงานของคุณคืออะไร?" onChange={(e) => onTextJobTitleChange(e)} className="popup-jd-other-title-control" />
                                    }

                                </div>

                            </div>
                            <button className="popup-button-next element" id="gtag-submit-resume" onClick={clickSubmit} >ดำเนินการต่อ</button>
                        </div> :
                         currentStep == 3 ?
                        <><Step3Popup lookingJob={lookingJobs} lookingForjobClick={clickLookingJobs}></Step3Popup></> :

                        <>
                            <LoginComponent handleChangeFile={handleChangeFile}></LoginComponent>
                        
                        </>


                }

            </div>
        </>
    )
}

export default UploadComponent;


const Step3Popup = ({ lookingJob, lookingForjobClick }: any) => {

    const lookingJobs = lookingJob;

    return (
        <div className="popup-step-3-control">
            <img src={RESUME_ICON} className="popup-step-3-img-icon"></img>
            <ProgressBar animated now={100} className="popup-loading-json" />
            <span className="popup-text-header">เรากำลังตรวจสอบเรซูเม่ของคุณ กรุณารอสักครู่...</span>

            <div className="popup-divider-blue"></div>
            <div className="popup-looking-jobs-control">
                {
                    lookingJobs == '0' ? <div className="d-flex flex-column">
                        <span className="text-jobs">ระหว่างรอ มาคุยกันสักหน่อย คุณกำลัง หางานอยู่หรือเปล่า?</span>
                        <button className="button-jobs mx-auto" onClick={() => lookingForjobClick("NEEDING_URGENT_EMPLOYMENT")}>กำลังหางานอยู่</button>
                        <button className="button-jobs mx-auto" onClick={() => lookingForjobClick("SEARCHING_INTERESTING_JOB")}>ก็เปิดรับโอกาสใหม่ๆ</button>
                        <button className="button-jobs mx-auto" onClick={() => lookingForjobClick("NOT_LOOKING_FOR_JOB")}>ไม่ได้หางานอยู่นะ</button>
                    </div> : <span className="text-jobs">ขอบคุณที่คุยด้วยกัน! เราจะแนะนำะไรที่น่าสนใจให้คุณแน่นอน <br></br>
                        ระบบจะพาไปหน้าผลลัพธ์ทันที! เมื่อตรวจสอบเรซูเม่ของคุณเสร็จแล้ว</span>
                }

            </div>
        </div>
    )
}

const JobTitleComponent = ({ jdSuggest, clickJDSuggest, selectJobTitle, others }: any) => {

    const jdSuggestModel = jdSuggest as JDSuggestionModel[]

    return (

        <div className="popup-list-jobtitle-control">
            <div className="popup-divider-blue"></div>
            <div className="list-group ">
                {
                    jdSuggestModel &&
                    jdSuggestModel?.map((item: JDSuggestionModel, index: number) => {


                        return (

                            <>

                                <a href="#" key={index} onClick={() => clickJDSuggest(item, index)}
                                    className={
                                        index === selectJobTitle ?

                                            "list-group-item list-group-item-action popup-list-jobtitle active" :
                                            "list-group-item list-group-item-action popup-list-jobtitle"
                                    }>
                                    {item.job_title}</a>

                            </>
                        )
                    })
                }
                <a href="#" onClick={() => clickJDSuggest(others, 888)}
                    className={
                        selectJobTitle === 888 ?

                            "list-group-item list-group-item-action popup-list-jobtitle active" :
                            "list-group-item list-group-item-action popup-list-jobtitle"
                    }>
                    อื่นๆ</a>


            </div>
        </div>
    )
}