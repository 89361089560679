import "./footer.scss";
import FOOTER_LOGO from "../../assets/images/jobhack-logo-white.png"
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";


const FooterComponent = () => {
    const location = useLocation();
    const [isFooter, setIsFooter] = useState(true);
    useEffect(() => {
        try {
            if (location.pathname.includes("/techsauce")) {
                setIsFooter(false);
            }
        } catch {

        }

    }, [])
    return (

        <>
            {
                isFooter && <div className="footer-control">
                    <img src={FOOTER_LOGO} className="footer-logo" />
                </div>
            }

        </>
    )
}

export default FooterComponent;