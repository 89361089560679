const apiConfig = {

    path: {
        job_suggestion: "/job_suggestion",
        analyze: "/resume/analyze",
        update: "/resume/update",
        uuid: "/resume/uuid",
        upload_resume: "/resume/upload"
    }
}

export default apiConfig;