
var shajs = require('sha.js')
const AuthFunction = () => {


    return {
        PKCEencoding(verificationCode: string) {

            let result = shajs('sha256').update(verificationCode).digest('base64');

            result = result.replaceAll(/\+/g, '-')
                .replaceAll(/\//g, '_')
                .replace(/\=$/, '');
            return result;
        },
        randomString(length: number) {

            var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var result = '';
            for (var i = 0; i < length; i++) {
                result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
            }
            return result;

        }
    }
}

export default AuthFunction;