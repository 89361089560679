import React, { useState } from 'react';
import logo from './logo.svg';
import './App.scss';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LandingPage from './pages/landing/landing';
import ResultPage from './pages/result/result';
import { SigninContext } from './context/signin.context';
import { ProfileRama } from './model/rama/profile-rama.model';
import AnalyzeModel from './model/analyze.model';
import CookieConsent from 'react-cookie-consent';
import { Container } from 'react-bootstrap';

function App() {
  const [codeVerifier, setCodeVerifier] = useState();
  const [profileModel, setProfileModel] = useState<ProfileRama>();
  const [analyzeModel, setAnalyzeModel] = useState<AnalyzeModel>();
  return (

    <div className="App">
      <SigninContext.Provider
        value={{ codeVerifier, setCodeVerifier, profileModel, setProfileModel, analyzeModel, setAnalyzeModel }}
      >
        <BrowserRouter>

          <Routes>
            {/* <Route path="/" element={<MaintainPage />} /> */}
            <Route path="/" element={<LandingPage />} />
            <Route path="/techsauce" element={<LandingPage />} />
            <Route path="/techsauce/result" element={<ResultPage />} />
            <Route path="/result" element={<ResultPage />} />
            <Route path="*" element={<LandingPage />} />
            {/* <Route path="/campaign/valentine" element={<ValentineCampaignPage />} /> */}
          </Routes>
        </BrowserRouter>
      </SigninContext.Provider>
      <CookieConsent
        enableDeclineButton
        declineButtonText="ยอมรับเท่าที่จำเป็น"
        location="bottom"
        buttonText="ยอมรับทั้งหมด"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B", fontFamily: "LINESeedSansTH_Bd" }}
        declineButtonStyle={{ fontSize: "13px", borderRadius: "12px", fontFamily: "LINESeedSansTH_Bd" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px", borderRadius: "12px", fontFamily: "LINESeedSansTH_Bd" }}
        expires={150}
      >
        <Container>


          เว็บไซต์ของเราใช้คุกกี้ เพื่อมอบประสบการณ์ที่ดียิ่งขึ้นแก่คุณ และเพื่อเสนอเนื้อหา
          บริการ และโฆษณาที่คุณอาจสนใจ คลิกดูข้อมูลเพิ่มเติมได้ที่  <a href="https://scoutout.co/terms-th/">เงื่อนไขการใช้บริการ</a>
          และ <a href="https://scoutout.net/privacy-th/">นโยบายความเป็นส่วนตัว</a>

        </Container>

      </CookieConsent>
    </div>
  );
}

export default App;
