import axios from "axios";
import apiConfig from "../apiConfig";
import config from "../config"
import { initializeApp } from "firebase/app";
import { addDoc, arrayRemove, arrayUnion, collection, doc, getDoc, getDocs, getFirestore, orderBy, query, setDoc, Timestamp, updateDoc, where } from "firebase/firestore";

export default function ApiServices() {

    return {
        async getJDSuggestion() {
            return fetch(config.api.base_url + apiConfig.path.job_suggestion, {
                method: 'GET', headers: {
                    'x-api-key': config.api.x_api_key,
                }
            }
            ).then(res => res.json()).catch(err => console.log(err))
        },
        async getResultUUID(uuid) {
            return fetch(config.api.base_url + apiConfig.path.uuid + "/" + uuid, {
                method: 'GET', headers: {
                    'x-api-key': config.api.x_api_key,
                }
            }
            ).then(res => res.json()).catch(err => console.log(err))
        },
        async uploadResume(fileUpload, filename) {
            const formData = new FormData();
            formData.append("resume", fileUpload, filename);
            formData.append("type", "resume_checker");
            return axios.post(config.upload_resume.base_url + apiConfig.path.upload_resume, formData, {
                headers: {
                    "x-api-key" : config.upload_resume.x_api_key
                }
            } )
                .then(res => res.data)
                .catch(errors => errors);

        },
        async postResult(objectResult) {


            const formData = new FormData();
            for (var key in objectResult) {
                formData.append(key, objectResult[key]);
            }
            return axios.post(config.api.base_url + apiConfig.path.analyze, formData, {
                headers: {
                    // 'application/json' is the modern content-type for JSON, but some
                    // older servers may use 'text/json'.
                    // See: http://bit.ly/text-json
                    'x-api-key': config.api.x_api_key,
                }
            })
                .then(res => res.data)
                .catch(errors => errors);
        },
        async getIP() {

            return fetch('https://geolocation-db.com/json/', {
                method: 'GET'
            }).then(res => res.json()).catch(err => console.log(err))
        },
        async postResultUpdate(uuid: string, job_status: string) {
            const objectResult = { uuid: uuid, job_status: job_status }

            return fetch(config.api.base_url + apiConfig.path.update, {
                method: 'POST', headers: {
                    'content-type': 'application/json',
                    'x-api-key': config.api.x_api_key,
                },
                body: JSON.stringify(objectResult)
            }
            ).then(res => res.json()).catch(err => console.log(err))
        }, async createCustomAnalytic(eventName: any, eventParam: any, product: Object) {
            const app = initializeApp(config.firebase);
            const db = getFirestore(app);
            const docData = {
                created_date: Timestamp.now(),
                event_name: eventName,
                event_value: eventParam,
                product: product
            };
            return await addDoc(collection(db, "resume_analytic"), docData);
        }, async saveResult(result_uid: string, uuid: string, fileName: string, keyPath: string) {
            const app = initializeApp(config.firebase);
            const db = getFirestore(app);
            const docData = {
                results: [{
                    result_uid: result_uid,
                    file_name: fileName,
                    key_path: keyPath,
                    created_at: Timestamp.now()
                }]
            };
            return await setDoc(doc(db, "resume-users", uuid), docData);
        }, async updateResult(result_uid: string, uuid: string, fileName: string, keyPath: string) {
            const app = initializeApp(config.firebase);
            const db = getFirestore(app);
            const washingtonRef = doc(db, "resume-users", uuid);
            const docData = {
                result_uid: result_uid,
                file_name: fileName,
                key_path: keyPath,
                created_at: Timestamp.now()
            };
            // Set the "capital" field of the city 'DC'
            return await updateDoc(washingtonRef, {
                results: arrayUnion(docData)
            });
        }, async updateRemoveResult(result_uid: string, uuid: string, fileName: string, keyPath: string, timeStamp) {
            const app = initializeApp(config.firebase);
            const db = getFirestore(app);
            const washingtonRef = doc(db, "resume-users", uuid);
            const docData = {
                result_uid: result_uid,
                file_name: fileName,
                key_path: keyPath,
                created_at: timeStamp
            };
            // Set the "capital" field of the city 'DC'
            return await updateDoc(washingtonRef, {
                results: arrayRemove(docData)
            });
        }
        , async getResult(uuid) {
            const app = initializeApp(config.firebase);
            const db = getFirestore(app);
            const docRef = doc(db, "resume-users", uuid);
            return await getDoc(docRef);
        }
        , async checkResult(uuid: string) {
            const app = initializeApp(config.firebase);
            const db = getFirestore(app);
            const docRef = doc(db, "resume-users", uuid);
            return await getDoc(docRef);
        }
        , async oauthToken(code, codeVerifier) {

            return fetch(config.authen.api.host_url + config.authen.api.token, {
                method: 'POST', headers: {
                    'Content-Type': "application/x-www-form-urlencoded",
                }, body: new URLSearchParams({
                    'grant_type': config.authen.grant_type,
                    'code': code,
                    'code_verifier': codeVerifier,
                    'client_id': config.authen.client_id,
                    'redirect_uri': config.authen.redirect_uri
                })
            }
            ).then(res => res.json()).catch(err => console.log(err))
        }
        , async refreshToken(refresh_token) {

            return fetch(config.authen.api.host_url + config.authen.api.token, {
                method: 'POST', headers: {
                    'Content-Type': "application/x-www-form-urlencoded",
                }, body: new URLSearchParams({
                    'grant_type': 'refresh_token',
                    'refresh_token': refresh_token
                })
            }
            ).then(res => res.json()).catch(err => console.log(err))
        }
        ,
    }
}